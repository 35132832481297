// Custom Styles

html {
-webkit-font-smoothing: antialiased; /* This needs to be set or some font faced fonts look bold on Mac in Chrome/Webkit based browsers. */
-moz-osx-font-smoothing: grayscale; /* Fixes font bold issue in Firefox version 25+ on Mac */
}

p {
	@extend .houschka-pro-medium;
	font-size: 20px;
}

// Header
#header {
	.lg-container {
		@include media-breakpoint-up(lg){
			max-width: 1366px;
			width: 100%;
			padding-right: 15px;
			padding-left: 15px;
			margin-right: auto;
			margin-left: auto;
		};
	}
	.nav {
		justify-content: center;
		a.nav-link {
			@extend .houschka-pro-demibold;
			text-transform: uppercase;
			color: $off-white;

			&.active.sliding-middle-out:after {
				width: 100%;
				background: $red;
			}

			&.sliding-middle-out {
				display: inline-block;
				position: relative;

				&:after {
					content: '';
					display: block;
					margin: auto;
					height: 3px;
					width: 0;
					background: transparent;
					transition: width 0.5s ease, background-color 0.5s ease;
				}
				@include media-breakpoint-up(md) {
					&:hover:after {
						width: 100%;
						background: $red;
					}
				};
			}

			@media (min-width: 992px) and (max-width: 1033px) {
				padding: 8px 12px;
			}
			@media (min-width: 1034px) {
				padding: 8px 16px;
			}
		}
		@include media-breakpoint-up(lg){
			justify-content: flex-end;
		};
	}
	.mobile-nav {
		.nav-bar {
			.menu-toggle {
				padding: 10px 20px 5px 10px;
			}
			.hamburger {
				padding: 0;
			}
		}
		.nav {
			.nav-link {
				padding: 10px 20px;
				font-size: 20px;
			}
		}
	}
	.logo {
		padding: 20px;
		img {
			width: 300px;
			height: 50px;
		}
	}
}

// Hero Module
.mod-hero {
	.bg-image {
		min-height: 424px;
	}
	&.full-image {
		.bg-image {
			min-height: 350px;
		}
	}
	.btn {
		margin-top: 15px;
		margin-right: 5px;
	}
	.hero-text {
		text-align: center;
		padding: 27px;
		@include media-breakpoint-up(md){
			text-align: left;
			padding: 30px;
		};
		@include media-breakpoint-up(lg){
			padding: 45px 45px 60px 30px;
		};
		@include media-breakpoint-up(xl){
			padding: 90px 90px 120px 60px;
		};
	}
	.hero-heading {
		@extend .houschka-pro-bold;
		text-transform: uppercase;
		color: $off-white;
		font-size: 24px;
		@include media-breakpoint-up(md){
			font-size: 28px;
		};
	}
	.hero-paragraph {
		@extend .houschka-pro-medium;
		font-size: 24px;
		color: $off-white;
		margin-bottom: 0;
	}
}

.text-slab {
	p {
		color: #2A405D;
		@extend .houschka-pro-light;
		font-size: 24px;
		@include media-breakpoint-up(md){
			font-size: 28px;
		};
	}
}

// Content Module Heading Stripe / Image / Text

.mod-content-stripe {
	overflow: hidden;
	margin-top: 50px;
	margin-bottom: 50px;

	.content-wrapper,
	.section-title {
		@include media-breakpoint-up(xl) {
			padding-left: 83px;
			padding-right: 40px;
			&.images-left {
				padding-right: 83px;
				padding-left: 40px;
			}
		}
	}

	.content-text {
		padding-top: 40px;
		padding-bottom: 40px;
		a {
			color: $red;
			text-decoration: underline;
		}
	}

	.section-title,
	.content-heading {
		h2 {
			text-transform: uppercase;
			@extend .houschka-pro-bold;
			font-size: 24px;
			@include media-breakpoint-up(md) {
				font-size: 28px;
			};
		}
	}

	.section-title {
		text-align: center;
		h2 {
			color: $off-white;
			padding-top: 32px;
			padding-bottom: 32px;
			margin-bottom: 0;
		}
		@include media-breakpoint-up(md) {
			text-align: left;
		};
	}

	.content-heading {
		color: #2A405D;
		color: $blue;
		margin-bottom: 25px;
	}

	.content-images {
		img {
			@extend .off-white-border;
			margin-top: -20px;
			@include media-breakpoint-up(md) {
				margin-top: -60px;
			};
		}
		@include media-breakpoint-up(md) {
			padding-bottom: 50px;
		}
	}

	&.images-left {
		.image-2 {
			margin-left: -45px;
		}
	}

	&.images-right {
		.image-2 {
			margin-left: 45px;
		}
	}

	.image-2 {
		display: none;
		@include media-breakpoint-between(md, lg) {
			display: block;
		}
	}

	.image-3 {
		display: none;
		@include media-breakpoint-only(md) {
			display: block;
		};
	}
}

// Quote Module

.mod-quote {
	background-color: rgba(35, 40, 86, .03);
	blockquote {
		@extend .houschka-pro-italic-light;
		font-size: 24px;
		color: $red;
		margin-top: 50px;
		margin-bottom: 50px;
		.cite-wrapper {
			margin-top: 20px;
			@include media-breakpoint-up(md) {
				text-align: right;
			};
		}
		cite {
			text-align: right;
			@extend .houschka-pro-demibold;
			font-size: 22px;
		}
	}
}

// Button Links
.mod-button-links{
	.button-links-heading {
		@extend .houschka-pro-light;
		font-size: 28px;
		color: #2A405D;
		text-transform: uppercase;
	}
	a {
		@extend .houschka-pro-bold;
		margin-right: 15px;
		margin-top: 15px;
		&:last-child {
			margin-right: 0;
		}
		@include media-breakpoint-only(xs) {
			display: block;
			width: 100%;
		};
	}
}

// text-plus-image Module
.mod-text-plus-image {
	.custom-padding {
		@include media-breakpoint-up(xl) {
			padding-left: 120px;
			padding-right: 120px;
		};
	}
	.image-column {
		margin-bottom: 30px;
		@include media-breakpoint-up(md) {
			margin-bottom: 0;
		};
	}
	&.thin {
		padding-top: 30px;
		padding-bottom: 30px;
		@include media-breakpoint-up(md) {
			padding-top: 60px;
			padding-bottom: 60px;
		};
	}
	&.heavy {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	img {
		@extend .off-white-border;
	}
	p {
		font-size: 20px;
		&.thin {
			@extend .houschka-pro-light;
			color: #2A405D;
			line-height: 36px;
			@include media-breakpoint-up(md) {
				font-size: 28px;
			};
		}
		&.heavy {
			@extend .houschka-pro-medium;
			color: #3D3E43;
			line-height: 38px;
			@include media-breakpoint-up(md) {
				font-size: 30px;
			};
		}
	}
}

// Heading Module

.mod-heading {
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
	&[class*="slab-"] {
		padding-top: 30px;
		padding-bottom: 30px;
		h2 {
			color: $off-white;
			text-transform: uppercase;
			font-size: 20px;
			margin-bottom: 0;
			@include media-breakpoint-up(md) {
				font-size: 24px;
			};
		}
	}
	h2 {
		@extend .houschka-pro-bold;
		font-size: 24px;
		color: #2A405D;
		@include media-breakpoint-up(md) {
			font-size: 34px;
		};
	}
	&.slab-white {
		h2 {
			color: $red;
		}
	}
	@include media-breakpoint-up(md) {
		padding-top: 40px;
		padding-bottom: 40px;
	};
}

.mod-heading-plus-text {
	padding-top: 30px;
	padding-bottom: 30px;
	h2 {
		@extend .houschka-pro-bold;
		font-size: 24px;
		color: #2A405D;
		margin-bottom: 20px;
		@include media-breakpoint-up(md) {
			margin-bottom: 35px;
			font-size: 34px;
		};
	}
	a {
		text-decoration: underline;
	}
	&.slab-blue,
	&.slab-red {
		h2,
		a,
		.content {
			color: $off-white;
		}
	}
	&.slab-white {
		h2,
		.content {
			color: $blue;
		}
		a {
			color: $red;
		}
	}
	@include media-breakpoint-up(md) {
		padding-top: 60px;
		padding-bottom: 60px;
	};
}


// Footer

footer {

	background: #2a405d; /* Old browsers */
	background: linear-gradient(to bottom, #2a405d 0%,#232536 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2a405d', endColorstr='#232536',GradientType=0 ); /* IE6-9 */

	color: $off-white;

	.logo {
		margin-bottom: 25px;
		text-align: center;
		@include media-breakpoint-up(md) {
			text-align: left
		};
	}

	.links a {
		color: $off-white;
		&:hover,
		&:active,
		&:focus {
			color: $red;
		}
	}


	ul {
		@extend .houschka-pro-demibold;
		font-size: 20px;
		margin: 0;
		list-style: none;
		margin-bottom: 45px;
		padding: 0;
		li {
				color: $red;
				font-size: 30px;
				.text {
					font-size: 20px;
					color: $off-white;
					vertical-align: 4px;
				}
			}
			@include media-breakpoint-up(md) {
				margin-bottom: 0;
			};
		}

	.footer-quote {
		blockquote {
			color: #C7DFFF;
			@extend .houschka-pro-italic-demibold;
			font-size: 28px;
			line-height: 34px;
			margin-bottom: 45px;
			cite {
				display: block;
				color: #8EAED9;
				margin-top: -5px;
				@extend .houschka-pro-medium;
				font-size: 14px;
				text-transform: uppercase;
			}
		}
	}

	.footer-info {
		@extend .houschka-pro-demibold;
		font-size: 18px;
		text-align: center;
		a {
			display: inline;
			text-decoration: underline;
		}
		@include media-breakpoint-up(md) {
			text-align: right;
		};
	}
}


// Forms
.form-section {
	.form-heading {
		text-align: center;
		margin-bottom: 25px;
		h2 {
			text-transform: uppercase;
			@extend .houschka-pro-bold;
			color: $blue;
			font-size: 24px;
			@include media-breakpoint-up(md) {
				font-size: 28px;
			};
		}
	}
	.form-subheading {
		text-align: center;
		margin-bottom: 50px;
	}

	select,
	.form-control {
		@extend .houschka-pro-demibold;
		background-color: #F4F4F4;
		border: 1px solid #707070;
		border-radius: 0;
		font-size: 20px;
		line-height: normal;
		outline: 0;
		width: 100%;
		padding: 15px 15px;
		@include media-breakpoint-up(md) {
			font-size: 32px;
		}
		&::placeholder {
			color: rgba(55, 54, 57, .4);
		}
	}

	.long-text {
		font-size: 18px;
		@include media-breakpoint-up(md) {
			font-size: 20px;
		}
	}

	.form-control {
		color: $red;
		display: block;
	}

	select {
		color: rgba(55, 54, 57, .4);
		appearance: none;
		// background: url("/img/arrows.svg") 98% / 4% no-repeat #FFFCF5;
	}

	.btn.submit {
		min-width: 180px;
		display: block;
		width: 100%;
		@include media-breakpoint-up(md) {
			display: inline;
			width: auto;
		}
	}
}

.mod-team {
	.employee {
		padding: 16px;
		.bg-image {
			padding-bottom: 100%;
			margin-bottom: 15px;
		}
		.name {
			@extend .houschka-pro-medium;
			text-transform: capitalize;
			color: #1D1D1F;
			font-size: 24px;
			@include media-breakpoint-up(md) {
				font-size: 30px;
			}
		}
		.title {
			@extend .houschka-pro-light;
			text-transform: capitalize;
			color: #232856;
			font-size: 18px;
			@include media-breakpoint-up(md) {
				font-size: 20px;
			}
		}
		.email a {
			@extend .houschka-pro-medium;
			text-transform: lowercase;
			color: $red;
			font-size: 18px;
			@include media-breakpoint-up(md) {
				font-size: 20px;
			}
		}
	}
}

// Helpers

.off-white-border {
	border: 10px solid #FFF6E5;
	box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.stacked-images {
	&.images-left {
		.image-2 {
			margin-left: -45px;
		}
	}

	&.images-right {
		.image-2 {
			margin-left: 45px;
		}
	}

	.image-2 {
		display: none;
		@include media-breakpoint-between(md, lg) {
			display: block;
		}
	}

	.image-3 {
		display: none;
		@include media-breakpoint-only(md) {
			display: block;
		};
	}
}

.slab {
	&-blue {
		background-color: $blue;
	}
	&-red {
		background-color: $red;
	}
	&-white {
		background-color: $white;
	}
}

// Remove blue outline on buttons when clicked
button:focus {
	outline:0;
}

.bg-image {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.btn {
	@extend .houschka-pro-demibold;
	text-transform: uppercase;
	font-size: 20px;
}

.btn-off-white {
  	@include button-variant($off-white, $red, $red);
	color: $red;
	&:hover,
	&:active {
		background-color: lighten($off-white, 15%);
		color: $red;
	}
}

.btn-red {
  	@include button-variant($red, $red, $red);
	color: $off-white;
	&:hover,
	&:active {
		background-color: darken($red, 5%);
		color: $off-white;
	}
}

.btn-outline-off-white {
	@include button-outline-variant($off-white);
}

p:last-child {
	margin-bottom: 0;
}
